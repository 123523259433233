<template>
  <button
    v-on="$listeners"
    class="load-more-btn d-flex align-center justify-center"
  >
    <h5 class="h5 text-strong">{{ label }}</h5>
  </button>
</template>

<script>
export default {
  name: 'ButtonLoadMore',
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.load-more-btn {
  width: rem(150px);
  height: rem(50px);
  margin: 0 auto;
  border-bottom: rem(1px) solid $color-detail;
  text-decoration: none;
  outline: none;

  @include breakpoint ('sm-and-down') {
    width: rem(120px);
    height: rem(40px);
  }
}
</style>
